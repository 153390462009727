<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    v-if="userId && admin"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Planning
            </div>
          </template>
          <v-bottom-navigation
            :value="activeBtn"
            grow
            active-class="activatedBtn"
          >
            <v-btn @click="day = 1">
              <span style="font-size:15px">Jeudi 30 Septembre</span>
            </v-btn>

            <v-btn @click="day = 2">
              <span style="font-size:15px">Vendredi 01 Octobre</span>
            </v-btn>
          </v-bottom-navigation>
          <v-card-text>
            <div
              v-for="laywer in laywers.LawyerPlanning"
              :key="laywer.id"
            >
              <v-row v-if="laywer.Consultations.filter(function(item){ return item.Date == days[day] }).length">
                <v-col
                  cols="12"
                  md="12"
                >
                  Maitre {{ laywer.AVO_Name }}
                  <v-divider class="mx-4"></v-divider>
                </v-col>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Voir le planning</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col
                          md="3"
                          cols="6"
                          v-for="consultation in laywer.Consultations.filter(function(item){ return item.Date == days[day] })"
                          :key="consultation.id"
                        >
                          <v-card
                            class="mx-auto my-12"
                            max-width="374"
                          >
                            <v-card-title>
                              {{ consultation.TimeStart.split(':')[0] }}h{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}h{{ consultation.TimeEnd.split(':')[1] }} {{ consultation.Name }}
                            </v-card-title>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-text>{{ consultation.FBX_Name }}</v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-text v-if="consultation.USR_ID > 0">{{ consultation.USR_Name }} {{ consultation.USR_Surname }}</v-card-text>
                            <v-card-text v-else>Aucune réservation</v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { httpGet } from '@/plugins/axios'
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        day: 1,
        days: { 1: '2020-10-05', 2: '2020-10-06', 3: '2020-10-07' },
        modal: false,
        userId: null,
        activeBtn: 0,
        admin: null,
        laywers: null,
      }
    },
    mounted () {
      this.userId = this.$store.state.userId
      this.admin = this.$store.state.isAdmin
      this.getLawyersPlanning()
    },
    methods: {
      async getLawyersPlanning () {
        const response = await httpGet('getLawyersPlanning')
        this.laywers = response
      },
    },
  }
</script>

<style lang="sass">
.activatedBtn
  color: #174282!important
</style>
